import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Modal, Text, Tabs, Tab, Spacing, Alert } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import DurationHeader from 'ui/atoms/DurationHeader';
import { flatFareDiscountsTypes } from 'models/parseTrip';
import EarlyDiscounts from '../EarlyDiscounts';
import AvailabilityDiscounts from '../AvailabilityDiscounts';
import 'styles/components/search/ProviderInfo';
import ResultItinerary from '../ResultItinerary';
import Amenity from '../../../ui/atoms/Amenity';

/**
 * Renders the provider information.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The children.
 * @param {string[]} props.amenities - The list of amenities.
 * @param {number} props.initialTab - The initial active tab.
 * @param {Object[]} props.availabilityCategories - The availability categories.
 * @param {Object[]} props.providerDiscounts - The provider discounts.
 * @param {string} props.tripId - The trip ID.
 * @param {boolean} props.isOnTheWay - Indicates if the trip is on the way.
 * @returns {JSX.Element} The rendered component.
 */
const ProviderInfo = ({
  amenities,
  availabilityCategories,
  initialTab,
  providerDiscounts,
  children,
  tripId,
  isOnTheWay,
  departure,
  arrival,
  duration,
}) => {
  const { t } = useTranslation('search');
  const { SHOW_DISCOUNTS_ON_RESULTS_DETAILS, SHOW_DURATION_ON_TRIP_DETAILS } =
    useWhitelabelFeatures();
  const { brand } = useWhitelabelEnvs();
  const isCDS = brand === 'cds';
  const sortedAvailabilityCategories = availabilityCategories.sort((a, b) => a.total - b.total);
  const departureMoment = moment(departure);
  const arrivalMoment = moment(arrival);

  const updatedAmenities = amenities.map(
    (amenity) => (isCDS && amenity === 'bathroom' ? 'bathroomSigns' : amenity),
    // eslint-disable-next-line function-paren-newline
  );

  /**
   * Processes and categorizes discounts into flat fare discounts and sorted early discounts.
   *
   * @param {Array} discounts - An array of discount objects.
   * @returns {Object} An object containing two arrays:
   *   - flatFareDiscounts: An array of discounts that are of type 'flat_fare'.
   *   - sortedEarlyDiscounts: An array of discounts sorted by their total value in ascending order.
   */
  const getDiscounts = (discounts) => {
    const flatFareDiscounts = discounts
      .filter((discount) => flatFareDiscountsTypes.includes(discount.name))
      .map((discount) => ({
        ...discount,
        type: 'flat_fare',
      }));

    const sortedEarlyDiscounts = discounts
      .filter((discount) => !flatFareDiscountsTypes.includes(discount.name))
      .sort((a, b) => a.total - b.total);

    return { flatFareDiscounts, sortedEarlyDiscounts };
  };

  const { flatFareDiscounts, sortedEarlyDiscounts } = useMemo(
    () => getDiscounts(providerDiscounts),
    [providerDiscounts],
  );

  const modifiedCategories = sortedAvailabilityCategories.map((c) => {
    const newCategory = { ...c };
    if (newCategory.type === 'general' && sortedEarlyDiscounts.length > 0) {
      newCategory.total = Math.max(...sortedEarlyDiscounts.map((d) => d.total));
    }
    return newCategory;
  });

  const combinedCategories = [...modifiedCategories, ...flatFareDiscounts];

  return (
    <Modal
      responsiveSize="L"
      portalSelector=".portalModal"
      fixedHieght
      removeContentPadding
      title={t('title.trip_details', { context: brand })}
      modalTrigger={children}
      zIndex={2999999999}
    >
      <Tabs headerGrow active={initialTab}>
        <Tab label={t('tab.itinerary')} iconType="route">
          <Spacing vertical>
            {isOnTheWay && (
              <Alert
                alertType="info"
                title={t('trip_service_on_the_way', {
                  context: 'details',
                })}
                padding="S"
                titleMobileSize="M"
                iconSize="S"
                weightTitle="semibold"
              />
            )}
            {SHOW_DURATION_ON_TRIP_DETAILS && (
              <DurationHeader
                departureMoment={departureMoment}
                arrivalMoment={arrivalMoment}
                duration={duration}
              />
            )}
            <ResultItinerary tripId={tripId} />
            <Text weight="bold">{t('label.amenities_and_services')}</Text>
            <Spacing vertical alignItems="flex-start" size="XS">
              {updatedAmenities.map((amenity) => (
                <Amenity key={amenity} type={amenity} />
              ))}
            </Spacing>
          </Spacing>
        </Tab>
        {SHOW_DISCOUNTS_ON_RESULTS_DETAILS && (
          <Tab label={t('tab.discounts')} iconType="discountTag">
            <Spacing isResponsive size="L" vertical>
              <AvailabilityDiscounts availabilityCategories={combinedCategories} />
              {Boolean(sortedEarlyDiscounts.length) && (
                <EarlyDiscounts discounts={sortedEarlyDiscounts} />
              )}
            </Spacing>
          </Tab>
        )}
      </Tabs>
    </Modal>
  );
};

ProviderInfo.propTypes = {
  children: PropTypes.node.isRequired,
  amenities: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialTab: PropTypes.number.isRequired,
  availabilityCategories: PropTypes.array,
  providerDiscounts: PropTypes.array,
  tripId: PropTypes.string.isRequired,
  isOnTheWay: PropTypes.bool,
  departure: PropTypes.string,
  arrival: PropTypes.string,
  duration: PropTypes.string,
};

export default ProviderInfo;
